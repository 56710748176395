<template>
    <div v-if="activeExperiment && !$global.isProduction" class="experiment-controls">
        <Txt size="marker">
            Split test
        </Txt>
        <Toggle
            :value="currentVariant"
            :tabs="activeExperiment.variants"
            dark
            @update:value="setVariant($event)"
        >
            <template #default="{ tab }">
                <Txt size="xs" heading>
                    {{ tab }}
                </Txt>
            </template>
        </Toggle>
    </div>
</template>

<script>
import useExperiments from '@/store/experiments';

import Txt from '@/components/form/txt';
import Toggle from '@/components/form/toggle';

export default {
    name: 'ExperimentControls',
    components: { Txt, Toggle },
    inject: ['$global'],
    computed: {
        activeExperiment() {
            return useExperiments.state
                && useExperiments.state.activeExperiment;
        },
        currentVariant() {
            return this.activeExperiment?.currentVariant || null;
        }
    },
    methods: {
        setVariant(variant) {
            useExperiments.setVariant(variant);
        }
    }
};
</script>

<style lang="less">
.experiment-controls {
    position: fixed;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2147483002;
    border-radius: 8px;
    padding: 4px;
    background-color: var(--night);
    display: flex;
    align-items: center;
    gap: 12px;
    color: #FFF;
    > .txt-component { margin-left: 8px; }
    .tab { width: 40px; }
}
</style>
